.tab-controls {
    @include list-reset;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .indicator {
        position: absolute;
        left: 100%;
        top: -18px;
        display: block;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 3px solid #000;
        border-radius: 50%;
        transition: background-color 0.3s ease; }

    .text {
        display: inline-block;
        vertical-align: top; }

    li {
        position: absolute;

        &.active {

            .indicator {
                background-color: #eb0000; } }

        &:first-child {
            top: 585px;
            left: 261px; }

        &:nth-child(2) {
            top: 617px;
            left: 300px; }

        &:nth-child(3) {
            top: 602px;
            left: 448px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(4) {
            top: 570px;
            left: 473px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(5) {
            top: 545px;
            left: 521px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(6) {
            top: 497px;
            left: 582px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(7) {
            top: 473px;
            left: 640px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(8) {
            top: 370px;
            left: 670px;

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(9) {
            top: 79px;
            left: 568px;

            .indicator {
                left: auto;
                right: 100%;
                top: auto;
                bottom: -18px; } } } }
