.title-line {
    position: relative;
    display: block;
    overflow: hidden;

    span {
        display: block;
        transform: translateY(100%);
        transition: transform 0.4s ease 0.4s;
        white-space: nowrap;

        .swiper-slide-active & {
            transform: translateY(0); }

        ._inner.animated & {
            transform: translateY(0); } }

    &:nth-child(2) {

        span {
            transition-delay: 0.5s; } }

    &:nth-child(3) {

        span {
            transition-delay: 0.6s; } } }

._inner .accordion {

    .title-line {

        span span {
            transform: translateY(100%);
            transition-delay: 0s; } }

    &.appear {

        span span {
            transform: translateY(0); } }

    &.disappear {

        span span {
            transform: translateY(100%); } } }

.appearing-text {
    opacity: 0;
    transition: opacity 0.4s ease 0.5s;

    .swiper-slide-active & {
        opacity: 1; }

    ._inner.animated & {
        opacity: 1; } }

.js-animate {
    opacity: 0;
    transition: opacity 0.4s ease; }

.appear {
    animation-name: appear;
    animation-duration: 0.5s;
    animation-delay: 0.000001s;

    animation-fill-mode: forwards;

    &.flyTo {
        animation-name: appear, flyTo;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.5s;

        animation-fill-mode: forwards; }

    &.scale {
        animation-name: appear, scale;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.00001s;

        animation-fill-mode: forwards; }

    &.unscale {
        animation-name: appear, unscale;
        animation-duration: 0.4s, 0.4s;
        animation-delay: 0.00001s, 0.5s;

        animation-fill-mode: forwards; }

    &.from-right {
        animation-name: appear, from-right;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.000001s;

        animation-fill-mode: forwards; }

    &.from-left {
        animation-name: appear, from-left;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.000001s;

        animation-fill-mode: forwards; }

    &.from-top {
        animation-name: appear, from-top;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.000001s;

        animation-fill-mode: forwards; }

    &.from-bottom {
        animation-name: appear, from-bottom;
        animation-duration: 0.5s, 0.5s;
        animation-delay: 0.000001s;

        animation-fill-mode: forwards;

        animation-timing-function: cubic-bezier(.08,.03,.22,.87); } }


@keyframes scale {
    0% {
        transform: scale(0); }

    100% {
        transform: scale(1); } }

@keyframes unscale {
    0% {
        transform: scale(10) rotate(90deg); }

    100% {
        transform: scale(1) rotate(0); } }

@keyframes appear {
    0% {
        opacity: 0; }

    100% {
        opacity: 1; } }

@keyframes from-left {
    0% {
        transform: translateX(-20px); }

    100% {
        transform: translateX(0); } }

@keyframes from-right {
    0% {
        transform: translateX(20px); }

    100% {
        transform: translateX(0); } }

@keyframes from-top {
    0% {
        transform: translateY(-20px); }

    100% {
        transform: translateY(0); } }

@keyframes from-bottom {
    0% {
        transform: translateY(20px); }

    100% {
        transform: translateY(0); } }

@keyframes shake {
    0% {
        transform: translateX(0); }

    100% {
        transform: translateX(10px); } }

@keyframes flyTo {
    0% {
        transform: translateX(-200%); }

    100% {
        transform: translateX(0); } }
