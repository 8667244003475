.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: -40px;
    display: flex;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;
    overflow-y: scroll;

    &.opened {
        opacity: 1;
        pointer-events: auto;
        z-index: 100; } }

.modal {
    position: relative;
    left: -12px;
    margin: auto;

    &__close {
        @include btn-reset;
        position: absolute;
        top: 40px;
        right: 40px;
        width: 23px;
        height: 23px;
        z-index: 101;

        &:before,
        &:after {
            @include center;
            content: "";
            transform: translate(-50%, -50%) rotate(45deg);
            width: 32px;
            height: 1px;
            background-color: #000; }

        &:after {
            transform: translate(-50%, -50%) rotate(-45deg); } } }
