.slider-controls {
    @include list-reset;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .indicator {
        position: absolute;
        left: 100%;
        top: -18px;
        display: block;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 3px solid #000;
        border-radius: 50%;
        transition: background-color 0.3s ease;

        @media (max-width: 639px) {
            top: -10px;
            width: 10px;
            height: 10px;
            border-width: 2px; } }

    .text {
        display: inline-block;
        vertical-align: top; }

    li {
        position: absolute;

        &.active {

            .indicator {
                background-color: #eb0000; } }

        &:first-child {
            bottom: 58px;
            left: 95px;

            @media (max-width: 639px) {
                bottom: 29px;
                left: 48px; } }

        &:nth-child(2) {
            bottom: 25px;
            left: 135px;

            @media (max-width: 639px) {
                bottom: 12px;
                left: 68px; } }

        &:nth-child(3) {
            bottom: 40px;
            left: 283px;

            @media (max-width: 639px) {
                bottom: 20px;
                left: 142px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(4) {
            bottom: 74px;
            left: 307px;

            @media (max-width: 639px) {
                bottom: 37px;
                left: 154px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(5) {
            bottom: 99px;
            left: 355px;

            @media (max-width: 639px) {
                bottom: 50px;
                left: 178px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(6) {
            bottom: 146px;
            right: 77px;

            @media (max-width: 639px) {
                bottom: 73px;
                right: 39px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(7) {
            bottom: 170px;
            right: 30px;

            @media (max-width: 639px) {
                bottom: 85px;
                right: 15px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(8) {
            bottom: 274px;
            right: 51px;

            @media (max-width: 639px) {
                bottom: 137px;
                right: 26px; }

            .indicator {
                left: auto;
                right: 100%; } }

        &:nth-child(9) {
            top: 42px;
            right: 133px;

            @media (max-width: 639px) {
                top: 21px;
                right: 67px; }

            .indicator {
                left: auto;
                right: 100%;
                top: auto;
                bottom: -18px;

                @media (max-width: 639px) {
                    bottom: -10px; } } } } }
