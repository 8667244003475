.btn {
    @include btn-reset;
    @include hover;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 11px 24px 9px;
    text-transform: uppercase;
    text-align: center;
    font-family: $font-special;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #fff;
    background-color: $red;

    &::after {
        content: "";
        display: block;
        margin-left: 16px;
        margin-top: -3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 6px;
        border-color: $transparent $transparent $transparent #fff; } }

._mobile {

    .btn {
        font-family: $font;
        font-size: 14px;
        letter-spacing: 1.2px;

        @media (max-width: 639px) {
            padding: 5px 12px;
            font-size: 7px;
            line-height: 10px;
            letter-spacing: 0.6px;

            &::after {
                margin-left: 8px;
                margin-top: -1px;
                border-width: 2.5px 0 2.5px 3px; } } } }


