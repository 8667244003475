._mobile {

    .content-section {
        flex-direction: column;
        padding: 0 57px;
        padding-right: 50px;

        @media (max-width: 639px) {
            padding: 0 28px;
            padding-right: 25px; }

        h2 {
            margin: 0 0 62px;
            padding-top: 50px;
            width: calc(100% + 40px);
            font-family: $font-alt;
            font-weight: 400;
            font-size: 62px;
            line-height: 72px;
            letter-spacing: -1px;

            @media (max-width: 639px) {
                margin-bottom: 31px;
                margin-left: -5px;
                padding-top: 25px;
                width: calc(100% + 25px);
                font-weight: 400;
                font-size: 31px;
                line-height: 36px;
                letter-spacing: -1px; }

            .title-line {
                margin-bottom: -16px;

                @media (max-width: 639px) {
                    margin-bottom: -8px; } } }

        p {
            margin: 0 0 40px;
            font-size: 30px;
            line-height: 40px;

            @media (max-width: 639px) {
                margin-bottom: 20px;
                font-size: 15px;
                line-height: 20px; } }

        &__block-header {
            position: relative; }

        &__block-body {
            padding-top: 50px;

            @media (max-width: 639px) {
                padding-top: 25px; }

            h2 {
                margin: -20px 0 52px;
                padding-top: 0;
                font-family: $font-alt;
                font-weight: 400;
                font-size: 62px;
                line-height: 72px;
                letter-spacing: -1px;

                @media (max-width: 639px) {
                    margin: -10px 0 26px;
                    margin-left: -5px;
                    width: calc(100% + 30px);
                    font-size: 31px;
                    line-height: 36px;
                    letter-spacing: -0.5px; }

                .title-line {
                    margin-bottom: -16px;

                    @media (max-width: 639px) {
                        margin-bottom: -8px; } } } }

        &__images {
            position: relative;

            &._01 {
                width: 450px;
                height: 582px;

                @media (max-width: 639px) {
                    width: 225px;
                    height: 291px;

                    img {

                        &:nth-child(2) {
                            width: 176px; }

                        &:first-child {
                            width: 160px; } } } }

            &._02 {
                margin-left: -57px;
                width: 520px;
                height: 592px;

                @media (max-width: 639px) {
                    margin-left: -28px;
                    width: 260px;
                    height: 296px; }

                img {

                    &:first-child {
                        bottom: 0;
                        left: 0;

                        @media (max-width: 639px) {
                            width: 260px; } }

                    &:nth-child(2) {
                        top: 0;
                        left: 242px;

                        @media (max-width: 639px) {
                            left: 121px;
                            width: 104px; } } } }

            &._03 {
                width: 576px;
                height: 1024px;

                @media (max-width: 639px) {
                    width: 288px;
                    height: 512px;

                    img {

                        &:first-child {
                            width: 256px; }

                        &:nth-child(2) {
                            width: 200px; } } } }

            &._04 {
                width: 528px;
                height: 744px;

                @media (max-width: 639px) {
                    width: 264px;
                    height: 372px; }

                img {

                    &:first-child {
                        top: 0;
                        left: 0;

                        @media (max-width: 639px) {
                            width: 264px; } }

                    &:nth-child(2) {
                        bottom: 0;
                        right: 16px;

                        @media (max-width: 639px) {
                            right: 8px;
                            width: 160px; } } } }

            &._05 {
                width: 448px;
                height: 584px;

                @media (max-width: 639px) {
                    width: 224px;
                    height: 292px;

                    img {

                        &:first-child {
                            width: 160px; }

                        &:nth-child(2) {
                            width: 166px; } } } }

            &._06 {
                margin-left: -26px;
                width: 488px;
                height: 872px;

                @media (max-width: 639px) {
                    margin-left: -13px;
                    width: 244px;
                    height: 436px; }

                img {

                    &:first-child {
                        top: 190px;
                        left: 0;

                        @media (max-width: 639px) {
                            top: 95px;
                            width: 244px; } }

                    &:nth-child(2) {
                        right: 80px;
                        top: 5px;

                        @media (max-width: 639px) {
                            right: 40px;
                            width: 76px; } }

                    &:nth-child(3) {
                        bottom: 0;
                        left: 40px;

                        @media (max-width: 639px) {
                            left: 20px;
                            width: 160px; } } } }

            &._07 {
                margin-left: -57px;
                width: 624px;
                height: 792px;

                @media (max-width: 639px) {
                    margin-left: -28px;
                    width: 312px;
                    height: 396px;

                    img {

                        &:first-child {
                            width: 188px; }

                        &:nth-child(2) {
                            width: 244px; } } } }

            &._08 {
                width: 544px;
                height: 800px;

                @media (max-width: 639px) {
                    width: 272px;
                    height: 400px; }

                img {

                    &:first-child {
                        top: 0;
                        left: 0;

                        @media (max-width: 639px) {
                            width: 271px; } }

                    &:nth-child(2) {
                        bottom: 0;
                        right: 30px;

                        @media (max-width: 639px) {
                            right: 15px;
                            width: 160px; } } } }

            &._09 {
                width: 552px;
                height: 616px;

                @media (max-width: 639px) {
                    width: 278px;
                    height: 308px;

                    img {

                        &:first-child {
                            width: 160px; }

                        &:nth-child(2) {
                            width: 244px; } } } }

            &._10 {
                width: 584px;
                height: 899px;

                @media (max-width: 639px) {
                    width: 297px;
                    height: 450px; }

                img {

                    &:first-child {
                        top: 8px;
                        left: 15px;

                        @media (max-width: 639px) {
                            top: 4px;
                            left: 8px;
                            width: 199px; } }

                    &:nth-child(2) {
                        top: 246px;
                        right: 0;

                        @media (max-width: 639px) {
                            top: 123px;
                            width: 252px; } }

                    &:nth-child(3) {
                        left: 0;
                        bottom: 59px;

                        @media (max-width: 639px) {
                            bottom: 30px;
                            width: 160px; } } } }

            &._11 {
                width: 584px;
                height: 768px;

                @media (max-width: 639px) {
                    width: 297px;
                    height: 384px;

                    img {

                        &:first-child {
                            width: 188px; }

                        &:nth-child(2) {
                            width: 244px; } } } }

            &._12 {
                width: 560px;
                height: 808px;

                @media (max-width: 639px) {
                    width: 280px;
                    height: 404px; }

                img {

                    &:first-child {
                        top: 0;
                        left: 0;

                        @media (max-width: 639px) {
                            width: 280px; } }

                    &:nth-child(2) {
                        bottom: 0;
                        right: 40px;

                        @media (max-width: 639px) {
                            right: 20px;
                            width: 160px; } } } }

            img {
                display: block;
                height: auto; }

            &._diagonal {

                img {

                    &:first-child {
                        top: 0;
                        left: 0; }

                    &:nth-child(2) {
                        bottom: 0;
                        right: 0; }

                    &:nth-child(3) {
                        bottom: 0;
                        left: 33px; } } } } }

    .sign {
        position: absolute;

        &._01 {
            right: 0;
            bottom: 103px;

            @media (max-width: 639px) {
                bottom: 52px;

                img {
                    width: 86px; } } }

        &._02 {
            right: -23px;
            top: 0;

            @media (max-width: 639px) {
                right: -12px;

                img {
                    width: 65px; } } }

        &._03 {
            left: -48px;
            bottom: 77px;

            @media (max-width: 639px) {
                left: -24px;
                bottom: 38px;

                img {
                    width: 67px; } } }

        &._04 {
            bottom: 0;
            left: 0;

            @media (max-width: 639px) {

                img {
                    width: 84px; } } }

        &._05 {
            top: 193px;
            right: 0;

            @media (max-width: 639px) {
                top: 97px;

                img {
                    width: 70px; } } }

        &._06 {
            top: 0;
            right: 0;

            @media (max-width: 639px) {

                img {
                    width: 68px; } } }

        &._07 {
            top: 0;
            right: -10px;

            @media (max-width: 639px) {
                right: -5px;

                img {
                    width: 99px; } } }

        &._08 {
            bottom: 0;
            left: 0;

            @media (max-width: 639px) {

                img {
                    width: 85px; } } }

        &._09 {
            top: 224px;
            right: 0;

            @media (max-width: 639px) {
                top: 112px;

                img {
                    width: 91px; } } }

        &._10 {
            bottom: -22px;
            right: 71px;

            @media (max-width: 639px) {
                bottom: -11px;
                right: 35px;

                img {
                    width: 61px; } } }

        &._11 {
            top: 0;
            right: -44px;

            @media (max-width: 639px) {
                right: -22px;

                img {
                    width: 100px; } } }

        &._12 {
            bottom: 44px;
            left: -5px;

            @media (max-width: 639px) {
                bottom: 22px;
                left: -2px;

                img {
                    width: 205px; } } }

        img {
            display: block;
            height: auto; } } }
