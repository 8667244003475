.intro {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    max-height: calc(100vh - 159px);

    @media (max-width: 1600px) {
        max-height: calc(100vh - 129px);
        padding-bottom: 30px; }

    &._autoHeight {
        max-height: none;
        padding-bottom: 73px; }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 auto;
        //box-sizing: border-box
        padding: 0 80px;
        width: 100%;
        max-width: 1440px;
        max-height: 100%;

        @media (max-width: 1600px) {
            padding: 0 56px;
            max-width: 1104px; }

        @media (max-width: 1279px) {
            padding: 0;
            max-width: 100%; } }

    &__link-block {
        position: absolute;
        right: 232px;
        bottom: 10px;
        z-index: 2;

        @media (max-width: 1600px) {
            right: 113px;
            bottom: 10px; } }

    &__share-block {
        position: relative;
        top: 10px;
        margin-left: 168px;
        padding-top: 10px;

        @media (max-width: 1600px) {
            margin-left: 0; } }

    &__title-block {
        position: relative;
        box-sizing: border-box;
        padding-top: 95%;
        background-repeat: no-repeat;
        background-size: cover;
        border: 8px solid $red;

        @media (max-width: 639px) {
            border-width: 4px; } }

    &__title {
        @include center;
        margin: 0;
        margin-top: -20px;
        max-width: 100%;
        text-align: center;
        font-family: $font-alt;
        color: #fff;
        font-size: 62px;
        line-height: 72px;

        @media (max-width: 639px) {
            margin-top: -10px;
            font-size: 31px;
            line-height: 36px; }

        .title-line {
            margin-bottom: -20px;

            @media (max-width: 639px) {
                margin-bottom: -10px; } } }

    &__text-block {
        padding: 65px 55px;
        background-color: #fff;

        @media (max-width: 639px) {
            padding: 33px 23px; }

        p {
            margin: 0 0 40px;
            font-size: 30px;
            line-height: 40px;

            @media (max-width: 639px) {
                margin-bottom: 20px;
                font-size: 15px;
                line-height: 20px; } } }

    .swiper-button-prev,
    .swiper-button-next {
        @include btn-reset;
        margin: 0;
        width: 32px;
        height: 32px;
        background-color: $red;
        background-image: none;
        border-radius: 50%;

        img {
            @include center;
            display: block; } }

    .swiper-button-prev {
        top: 0;
        left: 0;

        img {
            margin-top: -1px; } }

    .swiper-button-next {
        top: auto;
        bottom: 0;
        right: 0;

        img {
            margin-top: 1px; } }

    .swiper-pagination {
        @include centerV;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 32px;
        height: 120px;
        font-size: 0;

        @media (max-width: 1600px) {
            margin-top: -35px; }

        &::before {
            content: "";
            @include center;
            width: 4px;
            height: 2px;
            background-color: #fff; }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #fff;
            background-color: $red;
            border-radius: 50%; } } }

.intro-slider {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    @media (max-width: 1600px) {
        max-height: 536px; }

    .swiper-wrapper {
        flex-grow: 1; }

    &__controls {
        @include centerV;
        right: 0;
        width: 32px;
        height: 120px;

        @media (max-width: 1600px) {
            margin-top: -35px; } }

    ._inner & {
        max-height: 704px;

        @media (max-width: 1600px) {
            max-height: auto; } } }

.intro-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    flex-grow: 1;
    background-color: #fff;

    &._inner {
        flex-direction: row; }

    &:first-child {

        &.swiper-slide-active {

            .appearing-text {
                opacity: 0;
                animation-delay: 0.7s;

                .animated & {
                    opacity: 1; } }

            .title-line {

                span {
                    transform: translateY(100%);
                    animation-delay: 0.7s;

                    &:nth-child(2) {
                        animation-delay: 0.9s; }

                    .animated & {
                        transform: translateY(0); } } } }

        .intro-slide__bg {
            transition-delay: 0.3s; } }

    ._inner & {
        background-color: $transparent;
        min-height: 700px;

        @media (max-width: 1600px) {
            min-height: 552px; } }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-size: cover;
        transform: translateY(-100%);
        transition: transform 0.5s ease;

        .swiper-slide-active & {
            transform: translateY(0); }

        .swiper-slide-prev & {
            transform: translateY(100%); }

        ._inner.animated & {
            transition-delay: 0.1s;
            transform: translateY(0); }

        ._inner & {
            background-size: 50% auto;
            background-position: center right;

            @media (max-width: 1600px) {
                background-size: 50% auto;
                background-position: left 552px top; } } }

    &__wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: 20px 40px 20px 11.5%;
        width: 50%;
        max-width: 50%;
        flex-grow: 1;
        background-color: #fff;

        @media (max-width: 1600px) {
            padding-top: 20px;
            padding-left: 10%;
            padding-right: 56px; }

        ._inner & {
            background-color: rgba(255, 255, 255, 0.9); } }

    &__title {
        margin: 0 0 30px;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 62px;
        line-height: 76px;
        letter-spacing: -1px;

        &>span {
            margin-bottom: -16px; }

        @media (max-width: 1600px) {
            font-size: 40px;
            line-height: 48px;

            &>span {
                margin-bottom: -8px; } }

        ._inner & {
            margin-bottom: 36px;
            font-weight: 700;
            font-size: 56px;

            @media (max-width: 1600px) {
                font-size: 40px;
                line-height: 48px; } } }

    &__text {
        margin: 0 0 26px;
        max-width: 390px;
        font-size: 17px;
        line-height: 26px;

        @media (max-width: 1600px) {
            font-size: 16px;
            line-height: 25px; }

        ._inner & {
            margin-bottom: 30px;
            max-width: 373px;
            line-height: 30px;

            @media (max-width: 1600px) {
                margin-bottom: 25px;
                max-width: 305px;
                font-size: 16px;
                line-height: 25px; } } }

    &__content {
        margin: 0 auto;
        text-align: center; }

    &__headline {
        position: relative;
        margin: 0 auto 30px;
        max-width: 580px;
        font-family: $font-alt;
        font-size: 62px;
        line-height: 76px;
        color: #fff;

        &>span {
            margin-bottom: -20px; } }

    &__link {
        position: relative;
        margin: 0 auto;
        width: 208px; }

    &__bg-wrapper {
        flex: none;
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right top; } }

._mobile {

    .intro {
        padding: 0;
        max-height: calc(100vh - 80px);

        @media (max-width: 639px) {
            max-height: calc(100vh - 40px); }

        &__wrapper {
            padding: 0; }

        .swiper-button-prev,
        .swiper-button-next {
            width: 48px;
            height: 48px;
            bottom: auto;
            top: 50%;
            margin-top: 35px;

            @media (max-width: 639px) {
                width: 24px;
                height: 24px;
                margin-top: 18px; }

            img {
                @media (max-width: 639px) {
                    width: 7px;
                    height: 12px; } } }

        .swiper-button-prev {
            left: 55px;

            @media (max-width: 639px) {
                left: 28px; }

            img {
                margin: 0;
                margin-left: -2px;

                @media (max-width: 639px) {
                    margin-left: -1px; } } }

        .swiper-button-next {
            right: 55px;

            @media (max-width: 639px) {
                right: 28px; }

            img {
                margin: 0;
                margin-left: 2px;

                @media (max-width: 639px) {
                    margin-left: 1px; } } } }

    .intro-slider {
        height: 100%;
        max-height: 100%; }

    .intro-slide {

        &__bg {
            bottom: -8px;
            transform: translateY(0);
            border: 8px solid $red;
            border-bottom: none;

            @media (max-width: 639px) {
                bottom: -4px;
                border-width: 4px; } }

        &__wrapper {
            height: auto;
            flex-grow: 0;
            margin: auto 72px 0;
            padding-bottom: 0;
            padding-top: 90px;
            padding-left: 56px;
            width: 100%;
            max-width: calc(100% - 144px);
            max-height: calc(100% - 130px);
            text-align: center;
            background-color: rgba(255, 255, 255, 0.9);

            @media (max-width: 639px) {
                margin: auto 36px 0;
                padding-top: 45px;
                padding-left: 28px;
                padding-right: 28px;
                max-width: calc(100% - 72px);
                max-height: calc(100% - 65px); } }

        &__title {
            margin-bottom: 33px;
            font-weight: 700;
            font-size: 46px;
            line-height: 56px;

            @media (max-width: 639px) {
                margin-bottom: 16px;
                font-size: 23px;
                line-height: 28px; }

            & > span {
                margin: 0; } }

        &__text {
            margin-bottom: 0;
            padding: 0 15px;
            font-size: 24px;
            line-height: 40px;

            @media (max-width: 639px) {
                padding: 0 8px;
                font-size: 12px;
                line-height: 20px; } }

        .logo-vs {
            position: relative;
            display: block;
            margin: 45px auto;
            width: 176px;

            @media (max-width: 639px) {
                margin: 23px auto;
                width: 88px; }

            img {
                display: block;
                width: 100%;
                height: auto; } }

        &__content {
            position: relative;
            top: 110px;
            display: flex;
            flex-direction: column;
            margin: auto;
            min-height: 265px;

            @media (max-width: 639px) {
                top: 55px;
                min-height: 133px; } }

        &__headline {
            margin-bottom: auto;

            @media (max-width: 639px) {
                font-size: 31px;
                line-height: 38px;

                & > span {
                    margin-bottom: -10px; } } }

        &__link {

            @media (max-width: 639px) {
                width: 104px; } }

        .mCSB_container {
            padding: 0; } }

    &._inner {

        .intro {
            height: auto;
            max-height: none; } } }

