.tabs {
    @include list-reset; }

.tab {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 17px 56px 0;
    opacity: 0;
    pointer-events: none;

    @media (max-width: 1279px) {
        padding-top: 24px;
        padding-left: 24px;
        padding-right: 24px; }

    &.active {
        position: relative;
        pointer-events: auto;
        opacity: 1; }

    h2 {
        margin: 0 0 29px;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 62px;
        line-height: 74px;
        letter-spacing: -1px;

        @media (max-width: 1279px) {
            margin-bottom: 20px;
            font-size: 50px;
            line-height: 60px; } }

    &__text {
        margin: 0 0 20px;
        max-width: 380px;
        min-height: 330px;
        font-size: 17px;
        line-height: 30px;

        @media (max-width: 1279px) {
            font-size: 14px;
            line-height: 24px; } }

    &__img-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        max-width: 488px; }

    &__img {
        width: 100%;
        max-width: 488px;

        img {
            max-width: 100%;
            height: auto; } }

    &__img-src {
        margin: 0;
        font-size: 14px;
        line-height: 32px;
        color: #c3c3c3;
        letter-spacing: 0.5px;

        span {
            margin-left: 6px; }

        img {
            display: block;
            width: 100%;
            height: auto; } } }
