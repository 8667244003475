.share-block {
    @include row-component;
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    letter-spacing: 0.9px;

    &__label {
        position: relative;
        margin-right: 168px;

        &::after {
            content: "";
            @include centerV;
            width: 135px;
            height: 1px;
            background-color: #fff;
            left: calc(100% + 15px); } } }

.ya-share2 {
    text-transform: uppercase;

    a {
        color: #fff; }

    &__list {
        @include list-reset;
        @include row-component; }

    &__item {
        margin-right: 40px; }

    &__link {
        @include row-component;
        @include hover; }

    &__badge {
        display: block;
        margin-right: 7px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center; }

    &__item_service_facebook {

        .ya-share2__badge {
            width: 9px;
            height: 16px;
            background-image: url("../images/svg/icon-fb.svg"); } }

    &__item_service_vkontakte {

        .ya-share2__badge {
            width: 20px;
            height: 14px;
            background-image: url("../images/svg/icon-vk.svg"); } }

    &__item_service_twitter {

        .ya-share2__badge {
            width: 19px;
            height: 16px;
            background-image: url("../images/svg/icon-tw.svg"); } } }
