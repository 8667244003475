.page-footer {

    &__wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 1104px;

        @media (max-width: 1279px) {
            box-sizing: border-box;
            padding: 0 40px; } } }


.footer-nav {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 35px;
    background-color: $red;

    ul {
        @include list-reset;
        padding-right: 40px;
        display: flex;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 2px;

        li {

            &:first-child {
                padding-left: 16px; }

            &.current {
                position: relative;

                a {
                    color: $orange; }

                &::before {
                    content: "";
                    @include center;
                    width: 16px;
                    height: 112px;
                    background-image: url("../images/selected-menu.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    pointer-events: none;
                    z-index: 2; } } }

        a {
            position: relative;
            @include hover;
            display: block;
            padding: 30px 40px;
            text-align: center;
            color: #fff;
            z-index: 2; } } }

.mobile-footer {
    //margin-bottom: 80px
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #aaaaaa;
    background-color: #212121;

    @media (max-width: 639px) {
        font-size: 10px;
        line-height: 20px; }

    &__wrapper {
        padding: 31px 40px;

        @media (max-width: 639px) {
            padding: 16px 20px; } }

    p {
        margin: 0; } }

._mobile {

    .page-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 3;

        &__wrapper {
            max-width: 100%;
            padding: 0; } }

    .footer-nav {
        display: block;
        margin-bottom: 0;

        ul {
            width: 100%;
            font-size: 17px;

            @media (max-width: 639px) {
                font-size: 8px; } }

        li {
            box-sizing: border-box;
            flex-grow: 1;
            border-left: 1px solid rgba(255, 255, 255, 0.3);

            &:first-child {
                padding: 0;
                border-left: none; }

            &.current {
                background-color: #eea547;

                &::before {
                    display: none; }

                a {
                    color: #fff; } } }

        a {
            padding-left: 0;
            padding-right: 0;
            width: 100%;
            text-align: center;

            @media (max-width: 639px) {
                padding: 10px 0; } } } }
