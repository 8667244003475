@font-face {
  font-family: 'Roboto';
  src: url('../fonts/RobotoRegular.woff2') format('woff2'),
       url('../fonts/RobotoRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/RobotoBold_0.woff2') format('woff2'),
       url('../fonts/RobotoBold_0.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplayRegular.woff2') format('woff2'),
       url('../fonts/PlayfairDisplayRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplayBold.woff2') format('woff2'),
       url('../fonts/PlayfairDisplayBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FS Elliot Pro';
  src: url('../fonts/FSElliotProBold.woff2') format('woff2'),
       url('../fonts/FSElliotProBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
