.link-block {
    box-sizing: border-box;
    padding: 15px 15px 31px;
    width: 240px;
    background-color: rgba(249, 250, 252, 0.2);
    border: 1px solid #f9fafc;
    opacity: 0;
    transition: opacity 0.7s ease 0.4s, transform 0.4s ease;

    .animated & {
        opacity: 1; }

    &:hover {
        transform: translateY(-24px); }

    &__img {
        width: 208px;

        img {
            display: block;
            width: 100%;
            height: auto; } }

    &__info {
        @include centerH;
        bottom: 30px;
        margin: 0 auto;
        box-sizing: border-box;
        padding: 18px 15px 0;
        width: 176px;
        text-align: center;
        font-family: $font-alt;
        font-size: 16px;
        line-height: 22px;
        background-color: #fff; }

    &__title {
        margin: 0 0 14px;
        font-weight: 400; }

    &__btn {
        margin: 0 8px -20px; } }
