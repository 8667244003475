.share-mobile {

    .ya-share2 {

        &__list {
            display: flex;
            justify-content: center; }

        &__item {
            margin: 60px 32px;
            width: 96px;
            height: 66px;
            background-repeat: no-repeat;
            background-position: center;
            font-size: 0;

            @media (max-width: 639px) {
                margin: 30px 16px;
                width: 48px;
                height: 33px; }

            .ya-share2__badge {
                background-image: none; }

            &_service_facebook {
                background-image: url("../images/svg/icon-fb-b.svg");
                background-size: auto 88px;

                @media (max-width: 639px) {
                    background-size: auto 44px; } }

            &_service_vkontakte {
                background-image: url("../images/svg/icon-vk-b.svg");
                background-size: 104px auto;

                @media (max-width: 639px) {
                    background-size: 52px auto; } }

            &_service_twitter {
                background-image: url("../images/svg/icon-tw-b.svg");
                background-size: 83px auto;

                @media (max-width: 639px) {
                    background-size: 42px auto; } } } } }
