.content-block {

    &__inner {
        margin: 0 auto;
        box-sizing: border-box;
        padding: 62px 2.99% 0;
        width: 100%;
        max-width: 1218px;
        background-color: #fff; }

    h2 {
        position: relative;
        margin: 0 0 37px;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 40px;
        line-height: 60px;
        letter-spacing: -1px;
        z-index: 1;

        .title-line {
            margin-bottom: -15px; } }

    p {
        margin: 0 0 30px;
        max-width: 320px;
        font-size: 17px;
        line-height: 30px;
        transition-delay: 0.2s; } }

.content-section {
    display: flex;

    &__col {
        position: relative;
        flex: none;
        box-sizing: border-box;

        &._wide {
            width: 55.7%;

            @media (max-width: 1279px) {
                max-width: 50%; } }

        &._narrow {
            width: 44.124%;

            &._left {
                padding-left: 56px; } } }

    &__header {
        position: absolute;
        display: inline-block;
        vertical-align: top;
        z-index: 3;

        img {
            display: block;
            width: 100%;
            height: auto;
            transform: translateY(100%);
            transition: transform 0.7s ease 0.1s; }

        div {
            display: inline-block;
            vertical-align: top;
            overflow: hidden;

            &.appear {

                img {
                    transform: translateY(0); } } } }

    &._noodles {

        .illustrations-block {
            margin-top: 18px;

            &__wrapper {
                width: 448px;
                height: 584px; } }

        .content-section__header {
            bottom: 83px;
            right: 107px; } }

    &._sea {
        margin-top: -38px;

        ._narrow {
            padding-top: 314px; }

        ._wide {
            padding-top: 120px; }

        .content-section__header {
            top: 0;
            left: 56px;
            width: 100%;
            display: flex;
            justify-content: space-between; }

        .illustrations-block {
            width: 100%;

            &__wrapper {
                width: 100%;
                height: 656px; } }

        .illustration {

            &._04 {
                top: 0;
                left: 72px; }

            &._03 {
                bottom: 0;
                left: 0;
                width: 100%;
                height: auto; } } }

    &._exotic {

        ._wide {
            left: 56px;
            padding-top: 20px; }

        ._narrow {
            left: 56px;
            margin-top: -115px; }

        .illustrations-block {

            &__wrapper {
                width: 600px;
                height: 584px; } }

        .content-section__header {
            top: -100px;
            right: 34px; } }

    &._fruits {
        max-height: 760px;

        ._narrow {
            left: 56px;
            padding-top: 242px;

            p {
                padding-left: 56px; } }

        ._wide {
            left: 73px;
            padding-top: 54px; }

        .illustrations-block {

            &__wrapper {
                width: 544px;
                height: 816px; } }

        .content-section__header {
            top: 52px;
            left: 56px; }

        .illustration {

            &._07 {
                top: 0;
                left: 0; }

            &._08 {
                bottom: 0;
                right: 56px;

                @media (max-width: 1279px) {
                    bottom: 95px; } } } }

    &._springs {

        ._wide {
            padding-top: 20px; }

        .content-section__header {
            top: 18px;
            right: 108px; }

        .illustrations-block {

            &__wrapper {
                width: 448px;
                height: 616px; } } }

    &._steam {
        padding-top: 112px;

        ._wide {
            left: -39px;
            padding-top: 190px; }

        .illustrations-block {
            position: relative;
            left: 56px;

            &__wrapper {
                width: 544px;
                height: 632px; } }

        .illustration {

            &._04 {
                bottom: 0;
                left: 0; }

            &._03 {
                top: 0;
                right: 0; } }

        .content-section__header {
            top: 0;
            left: 0;
            width: 353px;
            display: flex;
            justify-content: space-between;

            ._03 {
                padding-top: 17px; } } }

    &._fairies {
        padding-top: 22px;

        ._wide {
            left: 56px; }

        ._narrow {
            padding-top: 107px;
            left: 56px; }

        .illustrations-block {

            &__wrapper {
                width: 544px;
                height: 736px; } }

        .content-section__header {
            top: 0;
            right: -30px; } }

    &._tea {
        padding-top: 83px;
        max-height: 705px;

        ._narrow {
            padding-top: 118px;
            left: 56px; }

        ._wide {
            left: 73px; }

        .illustrations-block {

            &__wrapper {
                width: 544px;
                height: 816px; } }

        .content-section__header {
            top: -79px;
            left: 0; }

        .illustration {

            &._07 {
                top: 0;
                left: 0; }

            &._08 {
                bottom: 0;
                right: 56px;

                @media (max-width: 1279px) {
                    bottom: 95px; } } } }

    &._details {

        ._wide {
            padding-top: 18px; }

        .illustrations-block {

            &__wrapper {
                width: 544px;
                height: 616px; } }

        .content-section__header {
            top: 17px;
            right: 90px; } }

    &._speed {

        ._narrow {
            padding-top: 148px; }

        ._wide {
            padding-top: 64px; }

        .illustrations-block {

            &__wrapper {
                width: 576px;
                height: 584px; } }

        .content-section__header {
            top: 0;
            right: 152px; }

        .illustration {

            &._03 {
                top: 0;
                left: 0; }

            &._04 {
                bottom: 0;
                right: 0; } } }

    &._robots {

        ._wide {
            left: 56px;
            padding-top: 119px; }

        ._narrow {
            left: 56px;
            padding-top: 351px;

            h2 {
                left: -112px; } }

        .illustrations-block {

            &__wrapper {
                width: 616px;
                height: 720px; } }

        .content-section__header {
            top: -8px;
            right: 110px;
            width: 712px;
            display: flex;
            justify-content: space-between;

            .sign {

                &:first-child {
                    margin-top: 158px; } } } }

    &._link {
        padding-top: 90px;
        max-height: 897px;

        ._narrow {
            left: 56px; }

        ._wide {
            left: 72px;
            padding-top: 194px; }

        .illustrations-block {

            &__wrapper {
                width: 560px;
                height: 816px; } }

        .illustration {

            &._07 {
                top: 0;
                left: {} }

            &._08 {
                bottom: 0;
                right: 70px;

                @media (max-width: 1279px) {
                    bottom: 95px; } } }

        .content-section__header {
            top: 17px;
            left: -58px; } } }

.illustrations-block {

    @media (max-width: 1279px) {
        max-width: 100%; }

    &__wrapper {
        position: relative;

        @media (max-width: 1279px) {
            max-width: 100%; } } }

.illustration {
    position: absolute;
    display: block;

    @media (max-width: 1279px) {
        max-width: 98%;
        height: auto; }

    &._01 {
        top: 0;
        left: 0; }

    &._02 {
        bottom: 0;
        right: 0; } }
