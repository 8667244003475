.audio-block {
    position: relative;
    margin-left: 20px;
    padding-top: 14px;

    @media (max-width: 1600px) {
        padding-top: 0; }

    audio {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0; }

    &__control {
        @include btn-reset;
        @include hover;
        position: relative;
        width: 32px;
        height: 32px;
        background-color: $red;
        border-radius: 50%;

        img {
            @include center;
            display: block;
            width: 20px;
            height: 20px;
            transition: opacity 0.3s ease;
            transform: scale(0.8) translate(-50%, -50%);
            transform-origin: top left; }

        .mute {
            opacity: 0; }

        &.playing {

            .mute {
                opacity: 1; }

            .sound {
                opacity: 0; } } } }


