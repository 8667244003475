.map-block {

    &__wrapper {
        display: flex;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
        background-color: #fff;

        @media (max-width: 1440px) {
            max-width: 98%; } }

    &__title {
        margin: 0;
        padding: 50px 57px 70px;
        font-family: $font-alt;
        font-weight: 400;
        font-size: 62px;
        line-height: 56px;
        letter-spacing: -1px; } }

.map {
    position: relative;
    flex: none;
    width: 840px;
    overflow: hidden;
    background-color: #138489;

    @media (max-width: 1279px) {
        width: 600px; }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #138489;
        background-image: url("../images/sea.jpg");
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: top right; } }

.island {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 70px;
    padding-top: 80px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 2px;
    color: #fff;
    background-image: url("../images/island.png");
    background-repeat: no-repeat;
    background-size: 647px auto;
    background-position: top 65px left 145px;

    @media (max-width: 1279px) {
        left: -163px; }

    h1 {
        margin: 0;
        font-size: 14px;
        line-height: 22px; } }


.info {
    flex: none;
    width: 600px;
    height: 784px;
    background-color: #fff;

    @media (max-width: 1440px) {
        width: calc(100% - 840px); }

    @media (max-width: 1279px) {
        width: calc(100% - 600px); } }

._mobile {

    .map-block {

        &__title {

            @media (max-width: 639px) {
                padding: 25px 28px 35px;
                font-size: 31px;
                line-height: 28px;
                letter-spacing: -0.5px; } } }

    .map {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 784px;

        @media (max-width: 639px) {
            height: 392px; } }

    .island {
        position: relative;
        left: 0;
        padding: 0;
        width: 603px;
        height: 628px;
        background-position: center;

        @media (max-width: 639px) {
            width: 302px;
            height: 314px;
            font-size: 7px;
            line-height: 11px;
            letter-spacing: 1px;
            background-size: 323px auto; } } }
