.client-link {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -56px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 7px;
    width: 240px;
    background-color: rgba(249, 250, 252, 0.2);
    border: 1px solid #fff;
    z-index: 1;
    transition: bottom 0.4s ease;

    &:hover {
        bottom: -32px; }

    &__img {
        width: 100%;

        img {
            display: block;
            width: 100%;
            height: auto; } }

    &__header {
        position: absolute;
        top: -16px;
        left: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 16px;
        height: 152px;
        background-color: #fff;

        img {
            display: block;
            max-width: 112px;
            height: auto; } }

    &__btn {
        position: absolute;
        bottom: -16px;
        left: 24px;
        right: 24px;
        padding-left: 10px;
        padding-right: 10px; } }

._mobile {

    .client-link {
        position: relative;
        bottom: auto;
        padding: 0;
        padding-top: 93%;
        width: 100%;

        &__img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;

            img {
                flex: none;
                display: block;
                width: 100%;
                height: auto; } }

        &__header {
            @include centerH;
            top: auto;
            bottom: 16px;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            padding: 0;
            width: calc(100% - 112px);
            max-width: 526px;
            height: auto;

            @media (max-width: 639px) {
                bottom: 8px;
                width: calc(100% - 56px); } }

        &__logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 145px;
            height: 112px;

            @media (max-width: 639px) {
                width: 73px;
                height: 56px; }

            img {
                display: block;
                flex: none;
                height: auto;
                width: auto;
                max-width: 80%;
                max-height: 80%; } }

        &__btn {
            position: static;
            flex-grow: 1;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 5px;

            @media (max-width: 639px) {
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 2.5px; }

            &::after {
                border-width: 10px 0 10px 12px;

                @media (max-width: 639px) {
                    margin-top: 1px;
                    border-width: 5px 0 5px 6px; } } } } }
