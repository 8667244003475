.main-nav {
    position: relative;
    background-color: $red;

    ul {
        @include list-reset;
        position: relative;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 2px;
        background-image: url("../images/glyph01.png"), url("../images/glyph02.png");
        background-repeat: no-repeat;
        background-size: 16px 32px;
        background-position: left 176px center, right 224px center;

        @media (max-width: 1600px) {
            background-position: left 153px center, right 200px center; }

        li {
            padding: 0 26px;

            &:first-child {
                margin-right: 30px;

                @media (max-width: 1600px) {
                    margin-right: 0;

                    a {
                        padding-left: 30px; } } }

            &:nth-child(2) {
                margin-right: auto; }

            &:last-child {
                margin-left: 22px;

                @media (max-width: 1600px) {
                    margin-left: 0;

                    a {
                        padding-right: 30px; } } }

            &.current {
                position: relative;

                a {
                    color: $orange; }

                &::before {
                    content: "";
                    @include center;
                    width: 16px;
                    height: 112px;
                    background-image: url("../images/selected-menu.png");
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center;
                    pointer-events: none;
                    z-index: 2; } } }

        a {
            @include hover;
            display: block;
            padding: 30px;
            text-align: center;
            color: #fff;

            @media (max-width: 1600px) {
                padding: 30px 15px; } } } }

.logo-hainan {
    @include center;
    display: block;
    width: 240px;
    transition: margin-top 0.4s ease;
    z-index: 2;

    &:hover,
    &:focus {
        outline: none;
        margin-top: -3px; }

    img {
        display: block;
        width: 100%;
        height: auto; } }

._mobile {

    .logo-hainan {
        position: static;
        transform: none;
        margin: 0 auto;

        @media (max-width: 639px) {
            width: 120px; } } }
