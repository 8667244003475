.tabs-slider {

    .swiper-button-prev,
    .swiper-button-next {
        @include btn-reset;
        top: 63px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        width: 48px;
        height: 48px;
        bottom: auto;
        background-color: $red;
        background-image: none;
        border-radius: 50%;

        @media (max-width: 639px) {
            top: 14px;
            width: 24px;
            height: 24px;
            margin-top: 18px; }

        img {
            @media (max-width: 639px) {
                width: 7px;
                height: 12px; } } }

    .swiper-button-prev {
        left: 55px;

        @media (max-width: 639px) {
            left: 28px; }

        img {
            margin: 0;
            margin-left: -2px;

            @media (max-width: 639px) {
                margin-left: -1px; } } }

    .swiper-button-next {
        right: 55px;

        @media (max-width: 639px) {
            right: 28px; }

        img {
            margin: 0;
            margin-left: 2px;

            @media (max-width: 639px) {
                margin-left: 1px; } } } }

.tab-slide {
    box-sizing: border-box;
    padding: 0 8px;

    @media (max-width: 639px) {
        padding: 0 4px; }

    &__title {
        margin: 0;
        padding-top: 51px;
        padding-bottom: 60px;
        font-family: $font-alt;
        font-weight: 400;
        text-align: center;
        font-size: 62px;
        line-height: 64px;

        @media (max-width: 639px) {
            padding-top: 25px;
            padding-bottom: 30px;
            font-size: 31px;
            line-height: 32px; } }

    &__img {

        img {
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 640px;
            height: auto; } }

    &__img-source {
        margin: 4px 0 0;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
        color: #c3c3c3;

        margin: 2px 0 0;
        font-size: 7px;
        line-height: 10px; }

    &__text {
        margin-top: 32px;
        padding: 0 48px;
        padding-right: 43px;
        font-size: 30px;
        line-height: 40px;

        @media (max-width: 639px) {
            margin-top: 16px;
            padding: 0 24px;
            padding-right: 21px;
            font-size: 15px;
            line-height: 20px; }

        p {
            margin: 0; } } }
