@import "normalize";
@import "fonts.scss";
@import "animation";

// ----------------------------------------------------------------------
//  // General styles
// ----------------------------------------------------------------------

html {
    width: 100%;
    min-height: 100%;
    font-size: 0.6945vw;
    display: flex;
    flex-direction: column;

    @media (max-width: 1023px) {
        font-size: 1.5625vw; } }

html * {
    max-height: 1000000px; }

body {
    margin: 0;
    width: 100%;
    min-width: 1552px;
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    background-color: #fff;
    background-image: url("../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;

    opacity: 0;
    transition: opacity 0.4s ease;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @media (max-width: 1600px) {
        min-width: 1216px;
 }        //background-size: 110%

    @media (max-width: 1280px) {
        min-width: 1000px; }

    &.animated {
        opacity: 1; }

    &.turn {

        @media (max-width: 960px) and (orientation: landscape) {

            &:after {
                content: "";
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10000;
                background-color: #fff;
                background-image: url("../images/rotate.png");
                background-repeat: no-repeat;
                background-size: 50% auto;
                background-position: center; } } }



    &.overflow {
        height: 100vh;
        overflow: hidden; }

    &._inner {
        padding-bottom: 100px; }

    &._mobile {
        display: flex;
        flex-direction: column;
        width: auto;
        min-width: auto;
        min-height: 100%;
        flex-grow: 1;

        &._inner {
            padding-bottom: 80px;
            background-image: none;

            @media (max-width: 639px) {
                padding-bottom: 40px; } } } }

.global-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    ._mobile & {
        width: auto;
        min-height: 100%;
        flex-grow: 1; }

    &.scrollable {
        height: 100vh; } }

.layout-positioner {
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%; }

.hidden {
    display: none !important; }

.invisible {
    position: absolute;
    z-index: -100;
    visibility: hidden; }

.svg-wrapper {
    position: absolute;
    opacity: 0;
    z-index: -100; }

.visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding:0 !important {}
    border:0 !important {}
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }

.index-page {
    box-sizing: border-box;
    padding: 40px;

    h1 {
        font-size: 22px;
        margin: 20px 0 0;
        font-family: Helvetica, sans-serif; }

    ol {
        padding: 10px 0 0 20px; }

    ol li {
        margin-bottom: 5px;
        list-style-type: decimal; }

    ol li a {
        font-size: 15px;
        font-family: Verdana, Arial, Helvetica, sans-serif;
        color: inherit; } }


