// ----------------------------------------------------------------------
    // Sizes
// ----------------------------------------------------------------------
$screenXXL: 1920px;
$screenXL: 1400px;
$screenL: 1280px;
$screenM: 1024px;
$screenS: 900px;
$screenXS: 768px;
$screenXS-s: 600px;
$screenXXS: 640px;
$screenMob: 500px;


// ----------------------------------------------------------------------
    // Colors
// ----------------------------------------------------------------------
$transparent: rgba(255, 255, 255, 0);
$textColor: #000;
$red: #a70003;
$orange: #eea547;

// ----------------------------------------------------------------------
    // Gradients
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
    // Fonts
// ----------------------------------------------------------------------
$font: Roboto, Arial, sans-serif;
$font-alt: 'Playfair Display', 'Times New Roman', serif;
$font-special: 'FS Elliot Pro', Arial, sans-serif;
