.page-header {

    &__wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: 1104px;

        @media (max-width: 1600px) {
            max-width: 992px; } }

    &__row {
        display: flex;
        align-items: flex-start;
        padding: 10px 0 14px;

        @media (max-width: 1600px) {
            padding: 7px 0;
            max-height: 35px; } }

    .logo-vs {
        @include hover;
        display: block;
        width: 152px;

        @media (max-width: 1600px) {
            position: relative;
            top: 3px;
            margin-left: -56px;
            margin-bottom: -12px;
            z-index: 1; }

        img {
            display: block;
            width: 100%;
            height: auto; } }

    &__label {
        position: relative;
        top: 4px;
        left: -40px;
        align-self: flex-end;
        margin: 0;
        margin-right: auto;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        color: #fff;
        letter-spacing: 1.1px;

        @media (max-width: 1600px) {
            top: -4px;
            left: 55px;
            letter-spacing: 0.5px; } }

    &__home {
        margin-left: auto;
        margin-top: 14px;
        width: 32px;

        @media (max-width: 1600px) {
            margin-top: 0; }

        img {
            display: block;
            width: 100%;
            height: auto; } } }

._mobile {

    .page-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2; } }

.home-button {
    @include hover;
    position: fixed;
    top: 20px;
    right: 20px;
    width: 46px;
    z-index: 5;

    @media (max-width: 639px) {
        width: 32px; }

    img {
        display: block;
        width: 100%;
        height: auto; } }

